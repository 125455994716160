.login-body {
    margin: 0 auto;
    padding: 30px 40px;
    width: 100%;
    max-width: 20%;
    min-width: 380px;
    background: rgba(255, 255, 255, 0.85);
}
.admin-login-bg {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    background-image: url("../images/admin-login.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.admin-logo {
    padding-bottom: 64px;
}

.logo-img {
    margin: 0 auto;
    width: 100%;
    max-width: 308px;
}
.btn-common.forgot {
    width: 40%;
}

/**
 * Register
 */
.register-body {
    margin: 0 auto;
    padding: 25px 20px;
    width: 100%;
    max-width: 25%;
    min-width: 380px;
    background: rgba(255, 255, 255, 0.85);
}
